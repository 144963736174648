// @ts-ignore
import { ChakraProvider } from "@chakra-ui/react";
import { withEmotionCache } from "@emotion/react";
import fontLexend from "@fontsource/lexend/index.css";
import { cssBundleHref } from "@remix-run/css-bundle";
import type { LinksFunction, LoaderArgs } from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError } from
"@remix-run/react";
import { useSetAtom } from "jotai";
import React, { useContext, useEffect } from "react";
import { ClientStyleContext, ServerStyleContext } from "~/chakra-ui/context";
import type { SupportedLocales } from "~/libs/i18n";
import { resolveBestSupportedLocale, setLanguage } from "~/libs/i18n";
import { locales } from "~/locales";
import { authContextStateAtom } from "~/state/applicationState.ts";
import { theme } from "~/theme";
import { ClientHintCheck, getHints } from "~/utils/client-hints.tsx";
import { getCookieValue } from "~/utils/cookie-utils.ts";
import { getEnv } from "~/utils/env.server.ts";
import { getDomainUrl } from "~/utils/misc.server.ts";
import { getErrorMessage } from "~/utils/misc.ts";
import { useNonce } from "~/utils/nonce-provider.ts";
import { AuthSession } from "~/utils/session.server.ts";
import { log } from "./libs/logger";
import rootStyles from "./root.css";

export const links: LinksFunction = () => [
...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : []),
{
  rel: "stylesheet",
  href: fontLexend
},
{
  rel: "stylesheet",
  href: rootStyles
}];


interface DocumentProps {
  children: React.ReactNode;
}

export async function loader({ request, context }: LoaderArgs) {
  const cookies = request.headers.get("Cookie");
  const langCookie = getCookieValue(cookies, "app-lang");
  const clientHints = getHints(request);
  const preferredLocale = langCookie ?? clientHints.lang;
  const session = await new AuthSession(request);
  const user = await session.user(false, true);
  return json({
    user,
    requestInfo: {
      hints: clientHints,
      locale: resolveBestSupportedLocale(preferredLocale),
      origin: getDomainUrl(request),
      path: new URL(request.url).pathname
    },
    ENV: getEnv()
  });
}

const Document = withEmotionCache(
  ({ children }: DocumentProps, emotionCache) => {
    const serverStyleData = useContext(ServerStyleContext);
    const clientStyleData = useContext(ClientStyleContext);
    const nonce = useNonce();

    useEffect(() => {
      emotionCache.sheet.container = document.head;
      const tags = emotionCache.sheet.tags;
      emotionCache.sheet.flush();
      tags.forEach((tag) => {
        (emotionCache.sheet as any)._insertTag(tag);
      });
      clientStyleData?.reset();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // noinspection HtmlRequiredTitleElement
    return (
      <html lang="en">
        <head>
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <ClientHintCheck nonce={nonce} />
          <Meta />
          <Links />
          {serverStyleData?.map(({ key, ids, css }) =>
          <style
            key={key}
            data-emotion={`${key} ${ids.join(" ")}`}
            dangerouslySetInnerHTML={{ __html: css }} />

          )}
        </head>
        <body>
          {children}
          <ScrollRestoration getKey={(location) => location.pathname} />
          <Scripts />
          <LiveReload />
        </body>
      </html>);

  }
);

export default function App() {
  const data = useLoaderData<typeof loader>();
  const setAuthContextState = useSetAtom(authContextStateAtom);
  const clientLocale = data.requestInfo.locale;

  setLanguage(clientLocale, locales[clientLocale as SupportedLocales]);

  useEffect(
    () =>
    setAuthContextState({
      businessUnits: data.user?.inScopeBusinessUnits || [],
      membership: data.user?.membership,
      isAdmin: data.user?.isAdmin || false
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <Document>
      <ChakraProvider theme={theme}>
        <Outlet />
      </ChakraProvider>
    </Document>);

}

export function ErrorBoundary() {
  const error = useRouteError();
  log.warn(error);

  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </div>);

  } else if (error instanceof Error) {
    return (
      <div style={{ padding: "20px" }}>
        <h1>💥 Error</h1>
        <p>{error.message}</p>
        <pre>{error.stack}</pre>
      </div>);

  } else {
    return <h1>{getErrorMessage(error)}</h1>;
  }
}